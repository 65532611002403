<template>
  <div>
    <b-container fluid>
        <b-row>
            <b-col>
                <div class="title"><h1>{{data.benefitTitle}}</h1></div>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12" class="bannerContent bgBannerBlue bennefitContentProduct">
                <div class="for_desktop">
                <b-row>
                    <b-col sm="5">
                    <img src="../../assets/images/icon/benefit-1.jpeg" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                    </b-col>
                    <b-col sm="7">
                        <b-row>
                        <b-col sm="12"><h1>{{data.benefitModalKecilTitle}}</h1></b-col>
                        <b-col sm="12"><p>{{data.benefitModalKecilDesc}}</p></b-col>
                        </b-row>
                    </b-col>
                </b-row>
                </div>
                <div class="for_tablet">
                <b-row>
                    <b-col sm="5">
                    <img src="../../assets/images/icon/benefit-1.jpeg" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                    </b-col>
                    <b-col sm="7">
                        <b-row>
                        <b-col sm="12"><h1>{{data.benefitModalKecilTitle}}</h1></b-col>
                        <b-col sm="12"><p>{{data.benefitModalKecilDesc}}</p></b-col>
                        </b-row>
                    </b-col>
                </b-row>
                </div>
                <div class="for_mobile">
                <b-row>
                    <b-col sm="7">
                        <b-row>
                        <b-col sm="12"><h1>{{data.benefitModalKecilTitle}}</h1></b-col>
                        <b-col sm="12"><p>{{data.benefitModalKecilDesc}}</p></b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="5">
                    <img src="../../assets/images/icon/benefit-1.jpeg" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                    </b-col>
                </b-row>
                </div>
            </b-col>
            <b-col sm="12" class="bannerContent bgBannerGreen bennefitContentProduct">
                <b-row>
                    <b-col sm="7">
                        <b-row>
                            <b-col sm="12"><h1>{{data.benefitSimpleTitle}}</h1></b-col>
                            <b-col sm="12"><p>{{data.benefitSimpleDesc}}</p></b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="5">
                        <img src="../../assets/images/icon/benefit-2.jpeg" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                    </b-col>
                </b-row>     
            </b-col>
            <b-col sm="12" class="bannerContent bgBannerBlue bennefitContentProduct">
                <div class="for_desktop">
                    <b-row>
                        <b-col sm="5">
                        <img src="../../assets/images/icon/benefit-3.jpeg" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                        </b-col>
                        <b-col sm="7">
                            <b-row>
                            <b-col sm="12"><h1>{{data.benefitAmanTitle}}</h1></b-col>
                            <b-col sm="12"><p>{{data.benefitAmanDesc}}</p></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
                <div class="for_tablet">
                    <b-row>
                        <b-col sm="5">
                        <img src="../../assets/images/icon/benefit-3.jpeg" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                        </b-col>
                        <b-col sm="7">
                            <b-row>
                            <b-col sm="12"><h1>{{data.benefitAmanTitle}}</h1></b-col>
                            <b-col sm="12"><p>{{data.benefitAmanDesc}}</p></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
                <div class="for_mobile">
                    <b-row>
                         <b-col sm="7">
                            <b-row>
                                <b-col sm="12"><h1>{{data.benefitAmanTitle}}</h1></b-col>
                                <b-col sm="12"><p>{{data.benefitAmanDesc}}</p></b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="5">
                            <img src="../../assets/images/icon/benefit-3.jpeg" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col sm="12" class="bannerContent bgBannerGreen bennefitContentProduct">
                <b-row>
                    <b-col sm="7">
                        <b-row>
                        <b-col sm="12"><h1>{{data.benefitPotensiTitle}}</h1></b-col>
                        <b-col sm="12"><p>{{data.benefitPotensiDesc}}</p></b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="5" class="bennefitContentProduct">
                    <img src="../../assets/images/icon/benefit-4.jpeg" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="12" class="bannerContent bgBannerBlue bennefitContentProduct">
                <b-row>
                    <b-col sm="7">
                        <b-row>
                        <b-col sm="12"><h1>{{data.benefitInovasiTitle}}</h1></b-col>
                        <b-col sm="12"><p>{{data.benefitInovasiDesc}}</p></b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="5">
                    <img src="../../assets/images/icon/benefit-5.jpeg" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="12" class="bannerContent bgBannerGreen bennefitContentProduct">
                <div class="for_desktop" style="margin-left:1.5rem;padding:1rem;">
                    <b-row>
                        <b-col sm="5">
                        <!-- <img src="../../assets/images/icon/benefit-6.jpeg" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" style="margin-top:28px" desc="Penghargaan 2018 Bisnisonline BGS"> -->
                        <hooper :infiniteScroll="true" :itemsToShow="1" :autoPlay="true" :playSpeed="5000" style="width:100%; height:450px;border-style: solid;border-width: 3px;border-color: white;">
                            <slide>
                                <img src="../../assets/images/1.jpg" width="100%" height="450px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                            <slide>
                                 <img src="../../assets/images/2.jpg" width="100%" height="450px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                            <slide>
                                 <img src="../../assets/images/3.jpg" width="100%" height="450px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                            <slide>
                                 <img src="../../assets/images/4.jpg" width="100%" height="450px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                            <slide>
                                 <img src="../../assets/images/5.jpg" width="100%" height="450px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                            <slide>
                                 <img src="../../assets/images/6.jpg" width="100%" height="450px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                        </hooper>
                        </b-col>
                        <b-col sm="7">
                            <b-row>
                            <b-col sm="12"><h1>{{data.benefitLiburanTitle}}</h1></b-col>
                            <b-col sm="12"><p>{{data.benefitLiburanDesc}}</p></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
                <div class="for_tablet">
                    <b-row>
                        <b-col sm="5" style="padding:30px">
                        <hooper :infiniteScroll="true" :itemsToShow="1" :autoPlay="true" :playSpeed="5000" style="width:100%; height:210px;border-style: solid;border-width: 3px;border-color: white;">
                            <slide>
                                <img src="../../assets/images/1.jpg" width="100%" height="210px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                            <slide>
                                 <img src="../../assets/images/2.jpg" width="100%" height="210px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                            <slide>
                                 <img src="../../assets/images/3.jpg" width="100%" height="210px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                            <slide>
                                 <img src="../../assets/images/4.jpg" width="100%" height="210px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                            <slide>
                                 <img src="../../assets/images/5.jpg" width="100%" height="210px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                            <slide>
                                 <img src="../../assets/images/6.jpg" width="100%" height="210px" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"/>
                            </slide>
                        </hooper>
                        </b-col>
                        <b-col sm="7">
                            <b-row>
                            <b-col sm="12"><h1>{{data.benefitLiburanTitle}}</h1></b-col>
                            <b-col sm="12"><p>{{data.benefitLiburanDesc}}</p></b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
                <div class="for_mobile">
                    <b-row>
                        <b-col sm="7">
                            <b-row>
                            <b-col sm="12"><h1>{{data.benefitLiburanTitle}}</h1></b-col>
                            <b-col sm="12"><p>{{data.benefitLiburanDesc}}</p></b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="5" style="padding:30px" @click="clickLiburan('all')">
                            <img src="../../assets/images/liburan/1.jpeg" width="100%">
                        </b-col>
                        <transition name="fade">
                        <b-col sm="5" v-if="showLiburan == 1" class="liburan" style="padding:30px; margin-top:-3rem">
                            <img src="../../assets/images/liburan/2.jpeg" width="100%">
                            <img src="../../assets/images/liburan/3.jpeg" width="100%">
                            <img src="../../assets/images/liburan/4.jpeg" width="100%">
                            <img src="../../assets/images/liburan/5.jpeg" width="100%">
                            <img src="../../assets/images/liburan/6.jpeg" width="100%">
                        </b-col>
                        </transition>
                    </b-row> 
                </div>
            </b-col>
        </b-row>
        <b-row style="margin-top:-2rem">
            <b-col class="mulaiJualanButton for_desktop">
                <a v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId='+ username +'&memberPackageId=PACKAGE_PLATINUM'"><img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imageMulaiJulan" width="50%" alt="Mulai Berjualan Bisnisonline BGS" desc="Mulai Berjualan Bisnisonline BGS"></a>
            </b-col>
            <b-col class="mulaiJualanButton for_tablet">
                <a v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId='+ username +'&memberPackageId=PACKAGE_PLATINUM'"><img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imageMulaiJulan" width="50%" alt="Mulai Berjualan Bisnisonline BGS" desc="Mulai Berjualan Bisnisonline BGS"></a>
            </b-col>
            <b-col class="mulaiJualanButton for_mobile">
                <a v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId='+ username +'&memberPackageId=PACKAGE_PLATINUM'"><img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imageMulaiJulan" width="80%" alt="Mulai Berjualan Bisnisonline BGS" desc="Mulai Berjualan Bisnisonline BGS"></a>
            </b-col>
        </b-row>
        
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
  import { Hooper, Slide } from 'hooper';
  import 'hooper/dist/hooper.css';
  export default {
      components:{
        Hooper,
        Slide
      },

    data(){
        return{
            bahasa:'id',
            data:{
                benefitTitle:null,
                benefitModalKecilTitle:null,
                benefitModalKecilDesc:null,

                benefitSimpleTitle:null,
                benefitSimpleDesc:null,

                benefitAmanTitle:null,
                benefitAmanDesc:null,

                benefitPotensiTitle:null,
                benefitPotensiDesc:null,

                benefitInovasiTitle:null,
                benefitInovasiDesc:null,

                benefitLiburanTitle:null,
                benefitLiburanDesc:null,

                imageMulaiJulan:"mulaiJualan_id.png"
            },
            noTelp:'6281298339718',
            username:null,
            showLiburan:0
        }
    },
    mounted(){
        this.username = window.location.pathname.replace("/", "");
        if(this.username == ''){
            this.username = 'happy'
        }
        axios.get(`https://api.bisnisonlinebgs.com/api/content/member/getMemberById${window.location.pathname}`).then(response => {
            this.noTelp = response.data.data[0].mobile_no
        })

        this.bahasa = localStorage.getItem('bahasa')
        this.data.imageMulaiJulan = `mulaiJualan_${this.bahasa}.png`

        axios.get(`../../lang_${this.bahasa}.json`).then(response => {
            // console.log(response.data.data[6].keuntungan[0].keuntunganTitle)

            this.data.benefitTitle = response.data.data[6].keuntungan[0].keuntunganTitle

            this.data.benefitModalKecilTitle = response.data.data[6].keuntungan[0].keuntunganModalTitle
            this.data.benefitModalKecilDesc = response.data.data[6].keuntungan[0].keuntunganModalDesc

            this.data.benefitSimpleTitle = response.data.data[6].keuntungan[0].keuntunganSimpleTitle
            this.data.benefitSimpleDesc = response.data.data[6].keuntungan[0].keuntunganSimpleDesc

            this.data.benefitAmanTitle = response.data.data[6].keuntungan[0].keuntunganAmanTitle
            this.data.benefitAmanDesc = response.data.data[6].keuntungan[0].keuntunganAmanDesc

            this.data.benefitPotensiTitle = response.data.data[6].keuntungan[0].keuntunganPotensiTitle
            this.data.benefitPotensiDesc = response.data.data[6].keuntungan[0].keuntunganPotensiDesc

            this.data.benefitInovasiTitle = response.data.data[6].keuntungan[0].keuntunganInovasiTitle
            this.data.benefitInovasiDesc = response.data.data[6].keuntungan[0].keuntunganInovasiDesc

            this.data.benefitLiburanTitle = response.data.data[6].keuntungan[0].keuntunganLiburanTitle
            this.data.benefitLiburanDesc = response.data.data[6].keuntungan[0].keuntunganLiburanDesc

        })
    },
    methods:{
        clickLiburan(){
            if(this.showLiburan == 0){
                this.showLiburan = 1
            } else {
                this.showLiburan = 0
            }
            
        }
    }

  }
</script>

